import { initialState } from 'redux/reducers/index';

import { unionBy, findIndex } from 'lodash';
import { ClientsAction, ClientsActionTypes, ClientsState } from 'types/app/clients';

export const clientsReducer = (
  state: ClientsState = initialState.app.clients,
  action: ClientsAction,
): ClientsState => {
  switch (action.type) {
    case ClientsActionTypes.MergeClients:
      return unionBy(state, action.payload, 'id');

    case ClientsActionTypes.AddOrUpdateClient: {
      const index = findIndex(state, { id: action.payload.id });

      if (index !== -1) {
        return [...state.slice(0, index), action.payload, ...state.slice(index + 1)];
      } else {
        return [...state, action.payload];
      }
    }

    default:
      return state;
  }
};
