import { AppDispatch } from 'redux/store';
import { getClientCall } from 'api/app/client';
import { ClientsActionTypes } from 'types/app/clients';

const clientActions = {
  getOne(id: number) {
    return async (dispatch: AppDispatch) => {
      const response = await getClientCall(id);
      dispatch({
        type: ClientsActionTypes.AddOrUpdateClient,
        payload: response,
      });

      return response;
    };
  },
};

export default clientActions;
