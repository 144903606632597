import { initialState } from 'redux/reducers/index';
import { filter } from 'lodash';
import {
  AvailableSlotsAction,
  AvailableSlotsActionTypes,
  AvailableSlotsState,
} from 'types/app/availableSlots';

export const AvailableSlotsReducer = (
  state: AvailableSlotsState = initialState.app.available_slots,
  action: AvailableSlotsAction,
): AvailableSlotsState => {
  switch (action.type) {
    case AvailableSlotsActionTypes.UpdateMultipleAvailableSlots:
      const { start_timestamp, end_timestamp, guests, available_slots } = action.payload;

      const updatedStateAfterRemoval = filter(state, (slot) => {
        return (
          slot.timestamp < start_timestamp ||
          slot.timestamp > end_timestamp ||
          slot.guests !== guests
        );
      });

      return [...updatedStateAfterRemoval, ...available_slots];
    default:
      return state;
  }
};
