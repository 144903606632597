import { AppState } from 'types';
import { AppDispatch } from 'redux/store';
import { AvailableSlotsActionTypes } from 'types/app/availableSlots';
import { getSlotDetailsByRange } from 'api/app/restaurant/availableSlots';

const availableSlots = {
  getAvailableSlotsForDay(guests?: number, reservation_id?: number) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
      const {
        time: { start, end },
        is_closed,
      } = getState().app.app.opening_hour;

      if (is_closed) {
        return;
      }

      const response = await getSlotDetailsByRange(start, end, guests, reservation_id);

      dispatch({
        type: AvailableSlotsActionTypes.UpdateMultipleAvailableSlots,
        payload: {
          start_timestamp: start,
          end_timestamp: end,
          guests,
          available_slots: response,
        },
      });

      return response;
    };
  },
};

export default availableSlots;
