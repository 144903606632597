import { createSelector } from 'reselect';
import { RootState } from 'redux/store';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';

const availableSlotsSelector = (state: RootState) => state.app.available_slots;

export const selectAvailableSlotsFromRange = (
  start_timestamp: number,
  end_timestamp: number,
  guests: number,
  only_active = false,
) =>
  createSelector([availableSlotsSelector], (slots) => {
    return slots.filter((slot) => {
      return (
        slot.timestamp >= start_timestamp &&
        slot.timestamp <= end_timestamp &&
        slot.guests === guests &&
        (!only_active || slot.available)
      );
    });
  });

export const selectEndTimesFromRange = (
  start_timestamp: number,
  end_timestamp: number,
  guests: number,
) =>
  createSelector([availableSlotsSelector], (slots) => {
    const firstBlocked =
      slots.find(
        (slot) =>
          slot.available_booking_length === 0 &&
          slot.timestamp >= start_timestamp &&
          slot.timestamp <= end_timestamp &&
          slot.guests === guests,
      )?.timestamp || end_timestamp;
    return slots
      .filter((slot) => {
        return (
          slot.timestamp >= start_timestamp &&
          slot.timestamp <= firstBlocked &&
          slot.guests === guests
        );
      })
      .map((slot) => {
        return {
          label: createMomentUtc(slot.timestamp).format('HH:mm'),
          value: slot.timestamp,
        };
      });
  });

export const selectSlotByTimestampAndGuests = (timestamp: number, guests: number) =>
  createSelector([availableSlotsSelector], (slots) => {
    return slots.find(
      (slot) => slot.timestamp === timestamp && slot.guests === guests && slot.available,
    );
  });
