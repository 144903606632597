import { WidgetSettingsState } from 'types/app/widgetSettings';

export const widgetSettingsInitialState: WidgetSettingsState = {
  default_language: '',
  available_languages: [],
  confirmation_type: '',
  show_rooms: false,
  min_guests: 1,
  max_guests: 1,
  booking_ahead: 120,
  booking_time: 120,
  update_time: 120,
  cancel_time: 120,
  max_slot_guests: null,
  max_day_guests: null,
  waiting_list: false,
};
