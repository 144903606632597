import { AppActionTypes } from 'types/app/app';
import { BookingActionTypes, BookingFormData } from 'types/app/booking';
import {
  createReservationCall,
  updateReservationCall,
  deleteReservationCall,
  changeReservationTimeCall,
  getReservationCall,
} from 'api/app/restaurant/reservation';
import { AppDispatch } from 'redux/store';
import { bookingInitialState } from 'redux/constants/app/bookingInitialState';
import {
  IComment,
  IReservation,
  IReservationClient,
  ReservationsActionTypes,
  UpdateReservationTime,
} from 'types/app/reservations';
import appActions from 'redux/actions/app/app';
import { UseFormReset } from 'react-hook-form';
import { timestamp } from 'utils/date-time/timestamp';
import { AppState } from 'types';
import { LoadingActionTypes } from 'types/app/loading';
import reservationsActions from 'redux/actions/app/reservations';

const bookingActions = {
  getReservation(id: number) {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getReservationCall(id);

        dispatch({
          type: ReservationsActionTypes.UpdateReservation,
          payload: response,
        });
      } catch (error) {
        throw error;
      }
    };
  },
  createReservation(data: BookingFormData) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: LoadingActionTypes.CreateReservationPending });

      dispatch({
        type: ReservationsActionTypes.CreateReservation,
        payload: {
          id: 'new',
          ...data,
        },
      });

      try {
        const { id } = await createReservationCall(data);
        dispatch({
          type: BookingActionTypes.UpdateBooking,
          payload: data,
        });

        dispatch({
          type: ReservationsActionTypes.CreateReservation,
          payload: {
            id,
            ...data,
          },
        });
        dispatch({ type: LoadingActionTypes.CreateReservationSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.CreateReservationRejected });
        throw error;
      } finally {
        dispatch({
          type: ReservationsActionTypes.DeleteReservation,
          payload: 'new',
        });
      }
    };
  },
  updateReservationTime(data: UpdateReservationTime) {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
      const originalReservation = getState().app.reservations.find((res) => res.id === data.id);

      dispatch({
        type: ReservationsActionTypes.UpdateReservationTime,
        payload: data,
      });

      dispatch({ type: AppActionTypes.DisableSubmit });

      try {
        await changeReservationTimeCall(data);
      } catch (error) {
        if (originalReservation) {
          dispatch({
            type: ReservationsActionTypes.UpdateReservationTime,
            payload: originalReservation,
          });
        }
        throw error;
      } finally {
        dispatch({ type: AppActionTypes.EnableSubmit });
      }
    };
  },
  deleteReservation(id: number) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: LoadingActionTypes.DeleteReservationPending });

      try {
        await deleteReservationCall(id);
        dispatch({
          type: ReservationsActionTypes.DeleteReservation,
          payload: id,
        });
        dispatch({ type: LoadingActionTypes.DeleteReservationSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.DeleteReservationRejected });
        throw error;
      }
    };
  },
  updateReservation(data: BookingFormData) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: LoadingActionTypes.UpdateReservationPending });
      dispatch(reservationsActions.setReservationLoading(data.id, true));
      try {
        await updateReservationCall(data);
        dispatch({
          type: ReservationsActionTypes.UpdateReservation,
          payload: data,
        });
        dispatch({ type: LoadingActionTypes.UpdateReservationSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.UpdateReservationRejected });
        throw error;
      } finally {
        dispatch(reservationsActions.setReservationLoading(data.id, false));
      }
    };
  },
  resetBookingToDefaults() {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: BookingActionTypes.ResetBooking,
        payload: bookingInitialState,
      });
    };
  },
  closeAndResetBooking(reset: UseFormReset<BookingFormData>) {
    return (dispatch: AppDispatch) => {
      dispatch(appActions.closeModal());
      dispatch(bookingActions.resetBookingToDefaults());
      reset(bookingInitialState);
    };
  },
  updateBooking(data: IReservation) {
    return (dispatch: AppDispatch, getState: () => AppState) => {
      const restaurantComments = data.comments?.filter((comment) => comment.user_id !== 0);
      const questComments = data.comments?.filter((comment) => comment.user_id === 0);

      const newData = {
        ...data,
        comments: restaurantComments,
        guest_comments: questComments,
      };

      const interval = getState().app.settings.reservation.interval;
      dispatch({
        type: BookingActionTypes.UpdateBooking,
        payload: {
          ...bookingInitialState,
          ...newData,
          start_time: timestamp(data.start_time).toSeconds,
          end_time: timestamp(timestamp(data.end_time).toSeconds).ceilToInterval(interval),
        },
      });
    };
  },
  setReservationComment(data: IComment) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: BookingActionTypes.SetReservationComment,
        payload: data,
      });
    };
  },
  deleteReservationComment(commentId: number) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: BookingActionTypes.DeleteReservationComment,
        payload: commentId,
      });
    };
  },
  updateReservationClient(client: IReservationClient) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: BookingActionTypes.UpdateReservationClient,
        payload: client,
      });
    };
  },
};

export default bookingActions;
