import { combineReducers } from 'redux';
import { AppState } from 'types';
import { calendarReducer } from './calendar';
import { reservationReducer } from './reservation';
import { applicationReducer } from './application';
import { authReducer } from './auth';
import { filtersReducer } from './filters';
import { floorPlanReducer } from './floorPlan';
import { notesReducer } from './notes';
import { notificationsReducer } from './notifications';
import { systemReducer } from './system';
import { themeReducer } from './theme';
import { configReducer } from 'redux/reducers/app/config';
import { restaurantReducer } from 'redux/reducers/app/restaurant';
import { authInitialState } from 'redux/constants/authInitialState';
import { applicationInitialState } from 'redux/constants/applicationInitialState';
import { systemInitialState } from 'redux/constants/systemInitialState';
import { notificationsInitialState } from 'redux/constants/notificationsInitialState';
import { calendarInitialState } from 'redux/constants/calendarInitialState';
import { reservationInitialState } from 'redux/constants/reservationInitialState';
import { filtersInitialState } from 'redux/constants/filtersInitialState';
import { notesInitialState } from 'redux/constants/notesInitialState';
import { floorPlanInitialState } from 'redux/constants/floorPlanInitialState';
import { themeInitialState } from 'redux/constants/themeInitialState';
import { configInitialState } from 'redux/constants/app/configInitialState';
import { settingsInitialState } from 'redux/constants/app/settingsInitialState';
import { timeAndLanguageReducer } from 'redux/reducers/app/timeAndLanguage';
import { appInitialState } from 'redux/constants/app/appInitialState';
import { appConfigReducer } from 'redux/reducers/app/app';
import { reservationSettingsReducer } from 'redux/reducers/app/reservationSettings';
import { bookingInitialState } from 'redux/constants/app/bookingInitialState';
import { bookingReducer } from 'redux/reducers/app/booking';
import { reservationsInitialState } from 'redux/constants/app/reservationsInitialState';
import { reservationsReducer } from 'redux/reducers/app/reservations';
import { usersReducer } from 'redux/reducers/app/users';
import { profileReducer } from 'redux/reducers/app/profile';
import { widgetSettingsReducer } from 'redux/reducers/app/widgetSettings';
import { profileInitialState } from 'redux/constants/app/profileInitialState';
import { openingHoursReducer } from 'redux/reducers/app/openingHours';
import { roomsInitialState } from 'redux/constants/app/roomsInitialState';
import { tablesInitialState } from 'redux/constants/app/tablesInitialState';
import { roomsReducer } from 'redux/reducers/app/rooms';
import { tablesReducer } from 'redux/reducers/app/tables';
import { specialOffersInitialState } from 'redux/constants/app/specialOffersInitialState';
import { specialOffersReducer } from 'redux/reducers/app/specialOffers';
import { loadingInitialState } from 'redux/constants/app/loadingInitialState';
import { loadingReducer } from 'redux/reducers/app/loading';
import { preferencesInitialState } from 'redux/constants/app/preferencesInitialState';
import { preferencesReducer } from 'redux/reducers/app/preferences';
import { timeManagementReducer } from 'redux/reducers/app/timeManagement';
import { notificationSettingsReducer } from 'redux/reducers/app/notificationSettings';
import { tableManagementReducer } from 'redux/reducers/app/tableManagement';
import { RootActionTypes } from 'types/app/root';
import { AvailableSlotsState } from 'redux/constants/app/availableSlotsState';
import { AvailableSlotsReducer } from 'redux/reducers/app/availableSlots';
import { clientsInitialState } from 'redux/constants/app/clientsInitialState';
import { clientsReducer } from 'redux/reducers/app/clients';

export const tableinAppInitialState = {
  app: appInitialState,
  preferences: preferencesInitialState,
  profile: profileInitialState,
  booking: bookingInitialState,
  clients: clientsInitialState,
  config: configInitialState,
  rooms: roomsInitialState,
  tables: tablesInitialState,
  reservations: reservationsInitialState,
  special_offers: specialOffersInitialState,
  settings: settingsInitialState,
  loading: loadingInitialState,
  available_slots: AvailableSlotsState,
};

export const initialState: AppState = {
  auth: authInitialState,
  application: applicationInitialState,
  system: systemInitialState,
  notifications: notificationsInitialState,
  calendar: calendarInitialState,
  reservation: reservationInitialState,
  filters: filtersInitialState,
  notes: notesInitialState,
  floorPlan: floorPlanInitialState,
  theme: themeInitialState,
  app: tableinAppInitialState,
};

const settingsReducer = combineReducers({
  restaurant: restaurantReducer,
  time_and_language: timeAndLanguageReducer,
  users: usersReducer,
  opening_hours: openingHoursReducer,
  reservation: reservationSettingsReducer,
  widget_settings: widgetSettingsReducer,
  time_management: timeManagementReducer,
  table_management: tableManagementReducer,
  notification_settings: notificationSettingsReducer,
});

const appReducer = combineReducers({
  app: appConfigReducer,
  preferences: preferencesReducer,
  profile: profileReducer,
  booking: bookingReducer,
  config: configReducer,
  clients: clientsReducer,
  rooms: roomsReducer,
  tables: tablesReducer,
  reservations: reservationsReducer,
  special_offers: specialOffersReducer,
  settings: settingsReducer,
  loading: loadingReducer,
  available_slots: AvailableSlotsReducer,
});

const mainReducer = () =>
  combineReducers({
    auth: authReducer,
    application: applicationReducer,
    system: systemReducer,
    calendar: calendarReducer,
    reservation: reservationReducer,
    notifications: notificationsReducer,
    filters: filtersReducer,
    notes: notesReducer,
    floorPlan: floorPlanReducer,
    theme: themeReducer,
    app: appReducer,
  });

export const rootReducer =
  () =>
  (state: AppState = initialState, action: any) => {
    if (action.type === RootActionTypes.ResetStoreToDefault) {
      return {
        ...state,
        app: action.payload,
      };
    }
    return mainReducer()(state, action);
  };
